<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add" }} Diary</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="diary-form">
          <v-text-field
            label="Title"
            v-model="fields.title"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>

          <tiptap
            v-model="fields.description"
            label="Description"
            class="mb-8"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          />

          <v-text-field
            label="Due Date"
            v-model="fields.due_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('due_date')"
            :error-messages="errors['due_date']"
          ></v-text-field>

          <v-select
            label="Assignee"
            v-model="fields.assignee"
            :items="users"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('assignee')"
            :error-messages="errors['assignee']"
          ></v-select>

          <v-switch
            label="Completed"
            v-model="fields.completed"
            inset
            :error="errors.hasOwnProperty('completed')"
            :error-messages="errors['completed']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="diary-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  props: {
    dialogType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      diary: {},
      types: ["office", "diary"],
      fields: {
        title: null,
        type: this.dialogType,
        completed: false,
        description: null,
        due_date: null,
      },
      errors: {},
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },
  },

  methods: {
    openForm(diary = null) {
      this.fields.type = this.dialogType;

      if (diary !== null) {
        this.diary = diary;

        this.isEditing = true;

        this.fields.assignee = diary.assigne_user
          ? diary.assigne_user.id
          : null;

        this.fields.title = diary.title;
        this.fields.description = diary.description;
        this.fields.due_date = diary.due_date;
        this.fields.completed = diary.completed;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.diaryId = this.diary.id;
      }

      this.$store
        .dispatch("sbpm/diariesStore/saveDiary", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.diary = {};
      this.fields = {
        title: null,
        type: this.dialogType,
        completed: false,
        description: null,
        due_date: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
