<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>
            {{ this.type | capitalize }}

            <span v-if="onlyCompleted"> Completed </span>
          </h1>
        </v-col>

        <v-col class="pl-12">
          <v-text-field
            label="Search Diaries"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom v-if="!onlyCompleted">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.diaryDialog.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Diary Entry</span>
            </v-tooltip>

            <v-tooltip bottom v-if="onlyCompleted">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="showAllTasks()"
                >
                  <v-icon small>mdi-progress-helper</v-icon>
                </v-btn>
              </template>
              <span>Show Open Tasks</span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="showCompleted()"
                >
                  <v-icon small>mdi-progress-check</v-icon>
                </v-btn>
              </template>
              <span>Show Completed Tasks</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="grid-row">
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header.text"
                    :class="header.align === 'right' ? 'text-right' : ''"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(diary, index) in diaries" :key="index">
                  <td @click="$refs.details.openForm(diary, searchType)">
                    {{ diary.title }}
                  </td>
                  <td>
                    <div v-if="diary.assigne_user">
                      {{ diary.assigne_user.full_name }}
                    </div>
                  </td>
                  <td>
                    <div>{{ formatDate(diary.due_date) }}</div>
                  </td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      color="green lighten-4 green--text"
                      @click="$refs.details.openForm(diary, searchType)"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>

                    <v-btn
                      bottom
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      @click="openDelete(diary)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-col>
        <v-col> <Details ref="details" /></v-col>
      </v-row>
    </v-container>
    <DiaryDialog ref="diaryDialog" :dialogType="this.type" />

    <v-dialog v-model="deleteDiary.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Diary</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDiary.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Details from "./components/Details.vue";
import DiaryDialog from "./components/DiaryDialog.vue";

export default {
  props: {
    type: String,
  },
  components: {
    DiaryDialog,
    Details,
  },

  data() {
    return {
      dialog: false,
      deleteDiary: {
        dialog: false,
        diary: {},
        loading: false,
      },
      onlyCompleted: false,
      searchType: this.type,
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Diaries",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Assignee", value: "assignee" },
        { text: "Due Date", value: "due_date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  mounted() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.$refs.details.dialog = false;
      }
    );
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  computed: {
    diaries() {
      let diaries = this.$store.getters["sbpm/diariesStore/all"];

      if (this.onlyCompleted) {
        diaries = diaries.filter((c) => c.completed == 1);
      } else {
        diaries = diaries.filter((c) => c.completed == 0);
      }

      if (this.searchTerm !== "") {
        diaries = diaries.filter((p) => {
          const title = p.title.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm);
        });
      }

      return diaries;
    },
  },

  methods: {
    showCompleted() {
      this.onlyCompleted = true;
      this.$refs.details.dialog = false;
    },

    showAllTasks() {
      this.onlyCompleted = false;
      this.$refs.details.dialog = false;
    },

    openDelete(diary) {
      this.deleteDiary.diary = diary;
      this.deleteDiary.dialog = true;
    },

    resetDelete() {
      this.deleteDiary.dialog = false;
      this.deleteDiary.diary = {};
      this.deleteDiary.loading = false;
    },

    saveDelete() {
      this.deleteDiary.loading = true;

      this.$store
        .dispatch("sbpm/diariesStore/deleteDiary", {
          appId: this.$route.params.id,
          diaryId: this.deleteDiary.diary.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteDiary.loading = false;
        });
    },
  },
};
</script>
<style>
.sidebar {
  width: 100%;
  min-height: 610px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
